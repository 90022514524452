import React, { useState, useEffect } from 'react'
import FileItem from './file'
import styles from './file.module.scss'
import { IFileGrid } from './file.interfaces'
import { urlName } from 'Utils/tools/naming'

const fileClass = 'file-grid-file';

export default function FileGrid({ files, onFileSelect, selected=0 }: IFileGrid) {
    const [active, setActive] = useState(selected);
	const [fileList, setFileList] = useState<JSX.Element[]>([])
	useEffect(() => {
		if (files) {
			setFileList(
				files.map((item, index) => {
					let id = urlName(item.name)
					let isSelected = index === active;
					return (
						<li key={id}>
								<FileItem
									id={id}
									className={`${fileClass} ${isSelected && styles.selected}`}
									name={item.name}
									ext={item.ext}
									images={item.images}
									onClick={() =>
										selectFile(id, index)
									}
								/>
						</li>
					)
				})
			)
		}
	}, [files, active])

	useEffect(() => {
		setActive(selected);
	}, [selected])

	useEffect(() => {
		if(files) {
			let file = files[active];
			selectFile(urlName(file.name));
		}
	}, [active, files])

	function selectFile(id: string, index?: number) {
        let fileElts = document.querySelectorAll(`.${fileClass}`);
        fileElts.forEach(elt => elt.classList.remove(styles.selected));

        let file = document.querySelector(`#${id}`);
		file?.classList.add(styles.selected);

        if(index !== undefined && files) {
            onFileSelect && onFileSelect(files[index]);
            setActive(index)
        }
    }

	return <ul className={styles.grid}>{fileList}</ul>
}
