import React from 'react'
import styles from './navbar.module.scss'
import { AutoTransLink, getDurations } from 'Animation/transitions'

import Gamepad from './gamepad/Gamepad'
import gsap from 'gsap'
import { useRef } from 'react'
import { forwardRef } from 'react'

const NavItem = ({ to, from, children, className, ...etc }) => (
	<li className={`${styles.navItem} navbar-item`}>
		<AutoTransLink
			wait
			className={`${styles.navLink} ${className}`}
			from={from}
			to={to}
			{...etc}
		>
			{children}
		</AutoTransLink>
	</li>
)

const NavPad = ({ to, from, children, className, ...etc }) => {	
	return (	
		<AutoTransLink
			wait
			className={`${className}`}
			from={from}
			to={to}
			{...etc}
		>
			<Gamepad
					animations="thumbstick"
					className={`nav-pad`}
					strokeWidth={2}
					style={{
						height: '75%',
						width: 'auto',
						position: 'absolute',
						top: '50%',
						left: 0,
						transform: 'translate(100%, -50%)', 
					}}
				/>
		</AutoTransLink>
)}

function openMobile(e) {
	let timeline = gsap.timeline()

	const [main, items] = getDurations(1.5, 1, 0.75)
	let itemCount = document.querySelectorAll(`.${styles.navItem}`).length

	timeline.addLabel('start')
	.to(`.${styles.navList}`, {opacity: 1, left: '0', duration: main, ease: 'expo.out'}, 'start')
	.fromTo(`.${styles.navItem}`, {x: '100%'}, {x: '0%', duration: items, stagger: items/itemCount, ease: 'expo.out'}, 'start')
}

export const Hamburger = forwardRef((props, ref) => (
	<svg ref={ref} fill="transparent" onClick={openMobile} viewBox="0 0 100 100" className={styles.hamburger} xmlns="http://www.w3.org/2000/svg">
		<path d="M 10 20 h 80 m -80 30 h 80 m -80 30 h 80 " strokeLinecap="round" />
	</svg>
))

const Navbar = props => {

	const menuRef = useRef()

	function closeMobile(e) {
		let style = window.getComputedStyle(menuRef.current)		
		if(style.display !== 'none') {
			let timeline = gsap.timeline()

			const [main, items] = getDurations(0.75, 1, 0.75)
			let itemCount = document.querySelectorAll(`.${styles.navItem}`).length

			timeline.addLabel('start')
			timeline.fromTo(`.${styles.navItem}`, {x: '0%'}, {x: '100%', duration: items, stagger: items/itemCount, ease: 'power2.in'}, 'start')
			.to(`.${styles.navList}`, {opacity: 0, left: '100%', duration: main, ease: 'power2.in'}, 'start+=0.35')
		}
	}

	return (
		// <TransitionPortal>
			<nav
				className={`${styles.navbar} site-navigation`}
				props={props}
			>
				<Hamburger ref={menuRef} />
				<NavPad to='/' from={props.from} />
				<ul
					className={styles.navList}
					onClick={closeMobile}
				>
					<NavItem
						to="/about"
						from={props.from}
						className={
							props.from === '/about'
								? styles.activeAbout
								: ''
						}
						children="About"
					/>
					<NavItem
						to="/experience"
						from={props.from}
						className={
							props.from ===
							'/experience'
								? styles.activeExperience
								: ''
						}
						children="Experience"
					/>
					<NavItem
						to="/blog"
						from={props.from}
						className={
							props.from === '/blog'
								? styles.activeBlog
								: ''
						}
						children="Blog"
					/>
					<NavItem
						to="/contact"
						text="Contact"
						from={props.from}
						className={
							props.from ===
							'/contact'
								? styles.activeContact
								: ''
						}
						children="Contact"
					/>
				</ul>
			</nav>
		// </TransitionPortal>
	)
}

export default Navbar
