import React, { useState } from "react"
import { config, animated, useSpring } from "react-spring"
import { useEffect } from "react"

// The function used to set the transformations
const trans = (x, y, rx, rz) =>
	`translate(${x}px,${y}px) rotateZ(${rx}deg) rotateX(${rz}deg)`

// Resets scroll transformations after this delay (milliseconds)
const scrollReset = 50

// The thresholds for the transformations
const mobility = {
    up: {
        rot: 35,
        tran: 20,
    },
    down: {
        rot: 25,
        tran: 10,
    },
    left: {
        rot: 15,
        tran: 15,
    },
    right: {
        rot: 15,
        tran: 15,
    },
}

export const ExperimentalThumbstick = ({
	children,
	id,
	mode,
	topPath,
	btmPath,
}) => {
	// === IDs === //
	let animId = `${id}--anim`
	let animTopId = `${id}--top`
	let animMaskId = `${animId}__mask`

	// === Animation Type === //
	const [transOrigin, setTransOrigin] = useState('')

	// === Animation === //
	const [props, set] = useSpring(() => ({
		xy: [0, 0, 0, 0],
		config: config.gentle,
		immediate: false
	}))
	

	// === Hooks === //

	useEffect(() => {

		// === TRANSFORM ORIGIN === //
		if (transOrigin.length === 0 && mode === "scroll") {
			setTransOrigin('65% 40%')
			// animClass = 'thumbstick-scroll'//styles.thumbstickScroll
		} else if (transOrigin.length === 0 && mode === "move") {
			// animClass = 'thumbstick-move'//styles.thumbstickMove
			setTransOrigin('25% 15%')
		}

		// === CALCULATIONS === //

		/**
		 * Normalizes value beteween 0 to 1 (from -1 to 1)
		 * @param {Number} val The value to normalize
		 */
		function normalize(val) {
			return (val + 1) / 2
		}

		/**
		 * Calculates the vertical transformations
		 * @param {Number} val The vertical value
		 */
		function verticalCalc(val) {
			// === Get Thresholds === //
			let rotDiff = mobility.up.rot - mobility.down.rot
			let rotMin = mobility.down.rot
			let tranSum = mobility.up.tran + mobility.down.tran
			let tranMin = mobility.down.tran

			return {
				rot: rotDiff * (1 - val) + rotMin,
				tran: tranSum * val - tranMin,
			}
		}

		/**
		 * Calculates the horizontal transformations
		 * @param {Number} val The vertical value
		 */
		function horizontalCalc(val) {
			// === Get Thresholds === //
			let rotSum = mobility.right.rot + mobility.left.rot
			let rotMin = mobility.left.rot
			let tranSum = mobility.right.tran + mobility.left.tran
			let tranMin = mobility.left.tran

			return {
				rot: rotSum * val - rotMin,
				tran: tranSum * val - tranMin,
			}
		}

		/**
		 * Sets the spring animation value
		 *
		 * @param {Number} dx The edited deltaX of the mouse event
		 * @param {Number} dy The edited deltaY of the mouse event
		 * @param {SpringConfig} type The SpringConfig to use (default "gentle")
		 */
		function setSpring(dx, dy, type=config.gentle, immediate=false) {
			dy = normalize(dy)
			dx = normalize(dx)

			let vert = verticalCalc(dy)
			let hori = horizontalCalc(dx)

			set({
				xy: [hori.tran, vert.tran, hori.rot, vert.rot],
				config: type,
				immediate: immediate
			})
		}

		// === MOVE === //

		/**
		 * Calculates and changes the transform values on a mouse move
		 * @param {MouseEvent} e The mouse event
		 */
		function onMove(e) {
			e = window.event || e // old IE support
			let deltaX = e.clientX - window.innerWidth / 2
			let deltaY = e.clientY - window.innerHeight / 2

			deltaX /= window.innerWidth / 2
			deltaY /= window.innerHeight / 2

			setSpring(deltaX, deltaY)
		}

		// === SCROLL === //

		// This timer stores the current reset Timeout
		let timer = -1

		/**
		 * Resets the transformations and clears the timer
		 */
		function reset(immediate=false) {
			setSpring(0, -0.5, config.wobbly, immediate)
			if (timer !== -1) {
				clearTimeout(timer)
				timer = -1
			}
		}

		/**
		 * Calculates and changes the transform values on a mouse scroll
		 * @param {WheelEvent} e The mouse scroll event
		 */
		function onScroll(e) {
			e = window.event || e // old IE support
			let deltaX = -Math.max(
				-1,
				Math.min(1, e.wheelDeltaX || -e.deltaX || -e.detail),
			)
			let deltaY = -Math.max(
				-1,
				Math.min(1, e.wheelDeltaY || -e.deltaY || -e.detail),
			)

			setSpring(deltaX, deltaY)

			// Set a new Timeout to reset after
			if (timer === -1) {
				timer = setTimeout(reset, scrollReset)
			} else {
				clearTimeout(timer)
				timer = setTimeout(reset, scrollReset)
			}
		}

		// === LEAVE === //

		/**
		 * Resets the transformations when the mouse leaves the window
		 * @param {MouseEvent} e The mouseout event
		 */
		function onOut(e) {
			e = e ? e : window.event
			let from = e.relatedTarget || e.toElement
			if (!from || from.nodeName === "HTML") {
				// Mouse left window
				reset(0, 0, config.wobbly)
			}
		}

		// === SETUP === //

		if (mode === "scroll") {
			window.addEventListener("mousewheel", onScroll, false)
			window.addEventListener(
				"wheel",
				onScroll,
				false,
			)
		} else if (mode === "move") {
			window.addEventListener("mousemove", onMove, false)
			document.addEventListener("mouseout", onOut, false)
		}

		// === INITIALIZE === //
		reset(true)

		// === CLEAN-UP === //

		return () => {
			if (mode === "scroll") {
				window.removeEventListener(
					"mousewheel",
					onScroll,
					false,
				)
				window.removeEventListener(
					"DOMMouseScroll",
					onScroll,
					false,
				)
			} else if (mode === "move") {
				window.removeEventListener(
					"mousemove",
					onMove,
					false,
				)
				document.removeEventListener(
					"mouseout",
					onOut,
					false,
				)
			}
		}
	})

	return (
		<g id={id}>
			{/* === Moving Part of Thumbstick === */}
			<g id={animId}>
				<defs>
					<mask id={animMaskId}>
						<rect
							width="100%"
							height="100%"
							fill="white"
						/>
						<animated.path
					id={animTopId}
					d={topPath}
					transform={``}
					style={{
						transformStyle: 'preserve-3d',
						willChange: 'transform',
						transformOrigin: transOrigin,
						transform: props.xy.interpolate(
							trans,
						),
					}}
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="black"
				/>
					</mask>
				</defs>

				<animated.path
					id={animTopId}
					d={topPath}
					transform={``}
					style={{
						transformStyle: 'preserve-3d',
						willChange: 'transform',
						transformOrigin: transOrigin,
						transform: props.xy.interpolate(
							trans,
						),
					}}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>

			{/* === Static Part of Thumbstick === */}
			<path
				d={btmPath}
				strokeLinecap="round"
				strokeLinejoin="round"
				mask={`url(#${animMaskId})`}
			/>
		</g>
	)
}