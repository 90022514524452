import React, { HTMLAttributes, useState, useEffect, useRef } from 'react'
import { IProjectTheme, IProjectTab } from '../project-templates.interfaces'
import styles from './engine-theme.module.scss'
import FileGrid from './components/file-grid';
import Icons from './components/icons';
import { IImage } from 'Utils/setup/types';
import { urlName } from 'Utils/tools/naming';
import { isImage, isVideo } from '../../../utils/tools/filename';
import nextId from 'react-id-generator';

interface IEngingeTheme extends IProjectTheme {}

export default function EngineTheme({project, tabs=[], hash}: IEngingeTheme) {
    const [activeTab, setActiveTab] = useState<IProjectTab|null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabList, setTabList] = useState(tabs);

    const gridRef = useRef<HTMLDivElement>(),
        infoRef = useRef<HTMLDivElement>(), 
        libRef = useRef<HTMLDivElement>();

    useEffect(() => {
        tabs.unshift({
            name: project.title,
            ext: 'scene',
            images: project.img ? [project.img] : [],
            default: true,
        })
        setTabList(tabs);
    }, [tabs, project])

    useEffect(() => {
		if(hash && hash !== activeTab?.name) {            
            let tabIndex = tabs.findIndex(tab => urlName(tab.name) === hash);
			if(tabIndex === 0) {
                setActiveTab(null);
                setActiveIndex(0);
            } else if(tabIndex > 0) {
                setActiveTab(tabs[tabIndex]);
                setActiveIndex(tabIndex);
            }            
		}
    }, [hash])

    function setTab(file: IProjectTab) {
        if(file.default) {
            setActiveTab(null);
        } else {
            setActiveTab(file);
        }
    }

    function getTabTitle(): string {
        return `${activeTab?.name}${activeTab?.ext && `.${activeTab.ext}`}`;
    }

    function toggleFullscreen(index: number) {
        if(gridRef) {
            if(index === 1) {
                gridRef.current?.classList.add(styles.hidden);
            } else {
                gridRef.current?.classList.remove(styles.hidden);
            }
        }
    }
    function togglePlayback(index: number) {

    }

    const Spacer = () => <div className={styles.spacer} />;

    return (
        <div className={styles.root}>
            <div className={styles.grid} ref={gridRef}>
                <div className={styles.controls}>
                        <ControlGroup icons={[Icons.Move, Icons.Scale, Icons.Rotate]} />
                        <Spacer />
                        <ControlGroup icons={[Icons.Pause, Icons.Play, Icons.Skip]} />
                        <Spacer />
                        <ControlGroup icons={[null, Icons.Fullscreen, Icons.FullscreenReversed]} onClick={toggleFullscreen} selected={2} />
                </div>
                <div className={styles.info} ref={infoRef}>
                    <TabBar tabs={['Information', 'Assets']} active={'Information'} />
                    <div className={styles.infoContent}>
                        <SectionTitle checked recessed title={activeTab ? getTabTitle() : project.title} className={styles.mainTitle} />
                        {
                            (activeTab?.type || activeTab?.role || activeTab === null) && (
                                    <SubSection content={[
                                        {Type: [<Dropdown key={234} items={[(activeTab?.type ? activeTab?.type : project.type)]} />]},
                                        {Role: [<Dropdown items={[(activeTab?.role ? activeTab?.role : project.role)]} />]},
                                    ]} />
                                )
                        }
                        {
                            (activeTab?.dates || activeTab === null) && (
                                    <SubSection title="Meta" content={[
                                        {Dates: [
                                            <Textbox label='From' value={(activeTab?.dates ? activeTab.dates.from : project.dates.from)} />,
                                            <Textbox label='To' value={(activeTab?.dates ? activeTab.dates.to : project.dates.to)} />
                                        ]}
                                    ]} />
                            )
                        }
                        {
                            (activeTab && !activeTab.type && !activeTab.role && !activeTab.dates) && (
                                <SubSection />
                            )
                        }
                        {
                            (activeTab?.desc || activeTab === null) && (
                                <SubSection title="Description" content={[
                                    {Text: [<Textarea rows={10} text={activeTab?.desc ? activeTab.desc : project.desc} />]}
                                ]} />
                            )
                        }
                        
                    </div>
                </div>
                <div className={styles.library} ref={libRef}>
                    <TabBar tabs={['Library', 'Console']} />
                    <nav className={styles.libraryContent}>
                        <FileGrid  
                            onFileSelect={file => setTab(file)}
                            files={tabList}
                            selected={activeIndex}
                            />
                    </nav>
                </div>
                <div className={styles.scene}>
                    {/* <TabBar tabs={['Scene']} active={'Scene'} /> */}
                    <Scene 
                        defaultImg={project.img}
                        images={activeTab?.images} 
                        html={activeTab?.html}
                        ext={activeTab?.ext} 
                        tab={activeTab}
                    />
                </div>
            </div>
        </div>
    )
}


interface ITabBar extends HTMLAttributes<HTMLDivElement> {
    active?: string;
    tabs?: string[];
}
function TabBar({active, tabs, children}: ITabBar) {
    return (
        <div className={styles.tabBar}>
            {
                tabs?.map((title, index) => {
                    let isActive = Boolean(active);
                    if(!isActive) {
                        isActive = index === 0;
                    } else {
                        isActive = title === active;
                    }
                    return (
                        <TabItem
                            key={index}
                            title={title} 
                            active={isActive}
                        />
                    )
                })
            }
            {children}
        </div>
    )
}
interface ITabItem extends HTMLAttributes<HTMLDivElement> {
    active?: boolean;
}
function TabItem(props: ITabItem) {
    return (
        <div className={[styles.tabItem, (props.active && styles.tabItemActive)].join(' ')}>
            {props.title}
        </div>
    )
}

interface IDropdown extends HTMLAttributes<HTMLSelectElement> {
    items: string[];
}
function Dropdown({items, ...etc}: IDropdown) {
    return (
        <select {...etc}>
            {
                items.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))
            }
        </select>
    )
}

interface ICheckbox extends HTMLAttributes<HTMLLabelElement> {
    checked?: boolean;
    label?: string;
}
function Checkbox({label, checked=true}: ICheckbox) {
    const [isChecked, setIsChecked] = useState(checked);
    return (
        <label className={styles.checkboxContainer}>
            <input type='checkbox' defaultChecked={isChecked} onClick={() => setIsChecked(!isChecked)} />
            <span className={styles.checkmark}><span>✔</span></span>
            <span>{label}</span>
        </label>
    )
}

interface ITextbox extends HTMLAttributes<HTMLElement> {
    label?: string;
    value?: string;
}
function Textbox({label, value, className, ...etc}: ITextbox) {
    return (
        <div className={`${styles.textbox} ${className}`} {...etc}>
            <label>{label}</label>
            <input readOnly type="text" value={value} />
        </div>
    )
}

interface ITextarea extends HTMLAttributes<HTMLElement> {
    text?: string;
    rows?: number;
}
function Textarea({text, rows=5}: ITextarea) {
    return (
        <div className={styles.textareaContainer}>
            <textarea rows={rows} readOnly value={text} />
        </div>
    )
}

interface ISectionTitle extends HTMLAttributes<HTMLElement> {
    icon?: string;
    checked?: boolean;
    recessed?: boolean;
}
function SectionTitle({icon, checked=true, recessed=false, title, className=''}: ISectionTitle) {
    return (
        <h2 className={[styles.sectionHeader, className].join(' ')}>
            <span className={styles.sectionIcon}>{icon}</span>
            <Checkbox checked={checked} />
            {
                recessed ? (
                    <Textbox value={title} className={`${styles.sectionTitle}`} />
                ) : (
                    <span className={styles.sectionTitle}>{title}</span>
                )
            }
            
        </h2>
    )
}

interface ISubSection extends HTMLAttributes<HTMLElement> {
    title?: string;
    checked?: boolean;
    recessed?: boolean;
    content?: Array<{[key: string]: JSX.Element[]}>;
}
function SubSection({title, checked=true, recessed=false, content=[]}: ISubSection) {
    return content ? (
        <div className={styles.subsection}>
            {title && <SectionTitle checked={checked} recessed={recessed} title={title} />}
            <div className={styles.sectionContent}>
                {
                    content.map((item, index) => {
                        let itemKey = Object.keys(item)[0];
                        return(
                            <React.Fragment key={index}>
                                <h4>{itemKey}</h4>
                                <div className={styles.sectionContentGrid}>
                                {
                                    item[itemKey].map((subitem, index) => (
                                        <React.Fragment key={index}>
                                            {subitem}
                                        </React.Fragment>
                                    ))
                                }
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </div>
    ) : (<></>)
}
interface IScene extends HTMLAttributes<HTMLElement> {
    ext?: string;
    images?: IImage[];
    html?: JSX.Element;
    defaultImg?: IImage;
    tab?: IProjectTab|null;
}

function Scene({defaultImg, tab}:IScene) {

    const [elts, setElts] = useState<JSX.Element[]>();
    const [currentTab, setCurrentTab] = useState<string|undefined>(undefined);

   function getImages() { 
        let imgs: JSX.Element[] = []
        if(tab && tab.images && !tab.html) {
            tab.images.slice(tab.firstTabIsThumbnail ? 1: 0).forEach((image, index) => {
                if(isImage(image.src)) {
                    imgs.push(<img key={index} src={image.src} alt={image.alt} />)
                } else if(isVideo(image.src)) {
                    imgs.push(
                        <Vid key={index} src={image.src} />
                    )
                }
            });
        } else if(defaultImg && defaultImg.src && !tab?.html) {            
            imgs.push(<img key={'default-img'} className={styles.sceneImageMain} src={defaultImg.src} alt={defaultImg.alt} />)
        }
        return imgs
    }

    useEffect(() => {
        setElts(getImages());
    }, [])

    useEffect(()=>{
        if(tab?.name !== currentTab) {
            setElts(getImages());
            setCurrentTab(tab?.name);
        }
    })

    return (
        <div className={styles.sceneContent}>
            {tab?.html}
            {elts}
        </div>
    )
}

const Vid = (props: HTMLAttributes<HTMLVideoElement>) => {

    return (
        <video
            className={styles.sceneContentVideo} 
            autoPlay={true}
            controls={true}
            loop={true}
            preload={'auto'}
            {...props}
        >
            <source src={props.src} />
        </video>
    )
}


/*
<div className={styles.controlGroup}>
    <div className={styles.control}><Icons.Move className={`${styles.pressed} control-tool`} id={styles.move} onClick={toggleTool} /></div>
    <div className={styles.control}><Icons.Scale className={`control-tool`} id={styles.scale} onClick={toggleTool} /></div>
    <div className={styles.control}><Icons.Rotate className={`control-tool`} id={styles.rotate} onClick={toggleTool} /></div>
</div>
*/
interface IControlGroup {
    icons: any[];
    selected?: number;
    enabled?: boolean;
    onClick?(index: number): void;
}
function ControlGroup({icons, enabled, onClick, selected=0}: IControlGroup) {

    const group = useRef<HTMLDivElement>(null);

    function toggle(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let elt = (e.target as HTMLElement).closest(`.${styles.control}`);
        
        let index = 0;
        group.current?.childNodes.forEach((node, i) => {
            (node as HTMLElement).classList.remove(styles.pressed);
            
            if((node as HTMLElement).id === elt?.id) {
                index = i;
            } 
        });
        elt?.classList.add(styles.pressed);

        if(onClick) {
            onClick(index);
        }
    }

    return (
        <div ref={group} className={styles.controlGroup}>
            {
                icons.map((Icon, index) => {
                    if(Icon === null) {
                        return <div key={index} />;
                    }
                    return (
                        <div className={`${styles.control} ${index === selected && styles.pressed} ${enabled && styles.iconEnabled}`} key={index} onClick={toggle} id={nextId()}>
                            <Icon />
                        </div>
                    )})
            }
        </div>
    )
}