export function urlName(name: string, alphabeticStart: boolean = false): string {
    let newName = name.replace(/[^\w-+]|_/g, '-');
    if(alphabeticStart && newName.charAt(0).match(/\d/)) {
        newName = 'id' + newName;
    }
    return newName;
}

export function camelCase(name: string): string {
    let words = name.split(/[^\w]|_/g);
    
    words.forEach((item, index) => {
        item = item.toLowerCase();

        if(index !== 0) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
        }

        words[index] = item;
    })

    return words.join('');
}

export function snakeCase(name: string): string {
    let words = name.split(/[^\w]|_/g);
    
    words.forEach((item, index) => {
        words[index] = item.toLowerCase();
    })

    return words.join('_');
}

export function padWith(text: string|number, pad: string|number, amount: number, onLeft: boolean = true): string {
    let str: string = `${text}`;

    if(str.length > amount) {
        return str;
    }

    if(onLeft) {
        str = `${`${pad}`.repeat(amount - str.length)}${str}`;
    } else {
        str = `${str}${`${pad}`.repeat(amount - str.length)}`;
    }

    return str;
}

export function formatDate(iso: string, longMonth: boolean = false, longDay: boolean = true): string {
    iso = iso.replace('Z', '');
    let date = new Date(iso);
    let monthLength = longMonth ? 'long' : 'short';
    let dayLength = longDay ? '2-digit' : 'numeric';
    return date.toLocaleString('en-US', { month: monthLength, day: dayLength, year: 'numeric' });
}