import React from 'react'
import styles from './file.module.scss'
import { IProjectTab } from '../../project-templates.interfaces'
import { urlName } from 'Utils/tools/naming'

export default function FileItem({
	name,
	ext,
	images,
	className,
	onClick,
	...etc
}: IProjectTab) {
	return (
		<a className={`${styles.file} ${className}`} onClick={onClick} href={`#${urlName(name)}`} {...etc}>
			<div className={styles.image}>
				{images && (
					<img
						src={images[0].thumbnail ? images[0].thumbnail : images[0].src}
						alt={images[0].alt}
					/>
				)}
			</div>
			<div className={styles.title}>
				{name}
				{ext && `.${ext}`}
			</div>
		</a>
	)
}
