import avionLogo from "../../../../src/projects/Avion/project.jpg";
import avionLogoThumb from "../../../../src/projects/Avion/project-thumb.jpg";
import avionLDD1 from "../../../../src/projects/Avion/images/Avion-Terminal-LDD-01.jpg";
import avionLDD2 from "../../../../src/projects/Avion/images/Avion-Terminal-LDD-02.jpg";
import avionLDD1Thumb from "../../../../src/projects/Avion/images/Avion-Terminal-LDD-01-Thumb.jpg";
import avionLDD2Thumb from "../../../../src/projects/Avion/images/Avion-Terminal-LDD-02-Thumb.jpg";
import ProjectTemplate from "../../../../src/templates/project-templates/project-template";
import * as React from 'react';
export default {
  avionLogo,
  avionLogoThumb,
  avionLDD1,
  avionLDD2,
  avionLDD1Thumb,
  avionLDD2Thumb,
  ProjectTemplate,
  React
};