const imageExts = ['png', 'jpg', 'jpeg', 'gif'];
const videoExts = ['mp4', 'mov', 'webm', 'ogg', 'm4v', 'avi', 'wmv', 'mpeg'];

enum FileType {
    None = '',
    Image = 'image',
    Video = 'video'
}

export function fileType(nameOrExt: string): FileType {
    let dot = nameOrExt.lastIndexOf('.');
    let ext = (dot !== -1) ? nameOrExt.slice(dot + 1) : nameOrExt;
    
    if(imageExts.includes(ext)) {
        return FileType.Image;
    } else if(videoExts.includes(ext)) {
        return FileType.Video;
    } else {
        return FileType.None;
    }
}

export function isImage(nameOrExt: string): boolean {
    return fileType(nameOrExt) === FileType.Image;
}
export function isVideo(nameOrExt: string): boolean {
    return fileType(nameOrExt) === FileType.Video;
}

