import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import nextId from 'react-id-generator'

const Snap = typeof window !== `undefined` ? require('snapsvg') : null

let FSvg = styled.svg`
    fill: transparent;
    stroke-width: 5;
`

interface IFSIcon extends HTMLAttributes<SVGElement> {
    reverse?: boolean;
}

export default function FullscreenIcon({onClick, reverse=false, ...etc}: IFSIcon) {

    const id = nextId('fs-icon--');
    const pathId = nextId('fs-icon--');
    let isClicked = reverse;
    let isAnimating = false;

    const paths = {
        expand: 'M 0 25 V 0 H 25 M 75 0 H 100 V 25 M 100 75 V 100 H 75 M 25 100 H 0 V 75',
        shrink: 'M 0 25 H 25 V 0 M 75 0 V 25 H 100 M 100 75 H 75 V 100 M 25 100 V 75 H 0'
    }

    function toggle(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        
        if(!isAnimating) {
            isAnimating = true;
            let path = Snap(`#${id}`).select(`#${pathId}`);
            path.animate(
                { d: isClicked ? paths.expand : paths.shrink },
                150,
                null,
                () => {
                    isAnimating = false;
                }
            )
            isClicked = !isClicked;
            if(onClick) {
                onClick(e);
            }
        }
    }
    

    return (
        <FSvg {...etc} id={id} onClick={toggle} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path id={pathId} d={reverse ? paths.shrink : paths.expand} />
        </FSvg>
    )
}

export const FullscreenIconStatic = ({reverse, ...etc}: IFSIcon) => (
	<FSvg {...etc} viewBox="-5 -5 110 110" xmlns="http://www.w3.org/2000/svg">
        {
            !reverse ? 
                (<path d="M 0 25 V 0 H 25 M 75 0 H 100 V 25 M 100 75 V 100 H 75 M 25 100 H 0 V 75" />)
            :
                (<path d="M 0 25 H 25 V 0 M 75 0 V 25 H 100 M 100 75 H 75 V 100 M 25 100 V 75 H 0" />)
        }
	</FSvg>
)
export const FullscreenIconReversed = ({reverse=true, ...etc}: IFSIcon) => (
	<FSvg {...etc} viewBox="-5 -5 110 110" xmlns="http://www.w3.org/2000/svg">
        {
            !reverse ? 
                (<path d="M 0 25 V 0 H 25 M 75 0 H 100 V 25 M 100 75 V 100 H 75 M 25 100 H 0 V 75" />)
            :
                (<path d="M 0 25 H 25 V 0 M 75 0 V 25 H 100 M 100 75 H 75 V 100 M 25 100 V 75 H 0" />)
        }
	</FSvg>
)
