import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './gamepad.module.scss'
import styles from './gamepad.module.scss'
import { AutoTransLink } from 'Animation/transitions'


export default class PadButton extends Component {

	render() {
		let linkEnabled = this.props.enabled

		let animAmount = this.props.offset
		let animDur = `${this.props.dur}s`
		let animEnabled = this.props.animate
		let animName = `${this.props.id}--anim`
		let animTiming = this.props.timing

		const ButtonAnim = ({ className }) => (
			<animateTransform
				attributeType="xml"
				attributeName="transform"
				type="translate"
				from="0 0"
				to={`0 ${animAmount}`}
				dur={animDur}
				begin="indefinite"
				end="indefinite"
				fill="freeze"
				keySplines={animTiming}
				className={className}
			/>
		)

		let Wrapper = ({ children }) =>
			this.props.enabled ? (
				<AutoTransLink
					wait={true}
					to={this.props.to}
					from={this.props.from}
				>
					{children}
				</AutoTransLink>
			) : (
				<div>{children}</div>
			)

		let titleId = `${this.props.id}--title`

		return (
			<Wrapper>
				<svg
					style={
						linkEnabled
							? null
							: {
									pointerEvents:
										'none',
							  }
					}
					className={[
						styles.basicSvg,
						styles.svgBtn,
					].join(' ')}
					viewBox={this.props.viewBox}
					xmlns="http://www.w3.org/2000/svg"
					aria-labelledby={titleId}
				>
					<title id={titleId}>{this.props.label}</title>
					<g
						className={
							animEnabled
								? styles.padBtn
								: ''
						}
						style={{ pointerEvents: 'all' }}
						id={this.props.id}
						transform={this.props.transform}
						onMouseEnter={e => {
							if (
								this.props
									.handleEnter
							) {
								this.props.handleEnter(
									this
								)
							}
							if (animEnabled) {
								let animations = document.getElementsByClassName(
									animName
								)
								for (let elt of animations) {
									elt.setAttribute(
										'from',
										'0 0'
									)
									elt.setAttribute(
										'to',
										'0 3'
									)
									elt.beginElement()
								}
							}
						}}
						onMouseLeave={e => {
							if (
								this
									.props
									.handleExit
							) {
								this.props.handleExit(
									this
								)
							}
							if (animEnabled) {
								let animations = document.getElementsByClassName(
									animName
								)
								for (let elt of animations) {
									elt.setAttribute(
										'from',
										'0 3'
									)
									elt.setAttribute(
										'to',
										'0 0'
									)
									elt.beginElement()
								}
							}
						}}
					>
						{/* --- Button Bottom --- */}
						<path

							d={this.props.btmPath}
						/>
						{/* --- Button Top --- */}
						<path

							d={this.props.topPath}
						>
							<ButtonAnim
								className={
									animName
								}
							/>
						</path>

						{/* --- Button Letter --- */}
						<path d={this.props.charPath}>
							<ButtonAnim
								className={
									animName
								}
							/>
						</path>
					</g>
				</svg>
			</Wrapper>
		)
	}
}
PadButton.defaultProps = {
	enabled: true,

	animate: true,
	dur: 0.1,
	offset: 3,
	timing: '0.4 0 0.2 1; 0.4 0 0.2 1',

	viewBox: '0 0 841 507',
	topPath:
		'M0,0c0,3.713,3.161,7.274,8.787,9.9c5.626,2.625,13.257,4.1,21.213,4.1c7.956,0,15.587,-1.475,21.213,-4.1c5.626,-2.626,8.787,-6.187,8.787,-9.9c0,-3.713,-3.161,-7.274,-8.787,-9.9c-5.626,-2.625,-13.257,-4.1,-21.213,-4.1c-7.956,0,-15.587,1.475,-21.213,4.1c-5.626,2.626,-8.787,6.187,-8.787,9.9',
	btmPath: 'M0,0v6c0,14,28,14,28,14c10,0,32,-4,32,-12v-8',
	charPath: 'M0,0v-10m0,0l-10,-6m10,6l10,-6',
}
PadButton.propTypes = {
	enabled: PropTypes.bool,

	topPath: PropTypes.string.isRequired,
	btmPath: PropTypes.string.isRequired,
	charPath: PropTypes.string.isRequired,

	dur: PropTypes.number,
	animate: PropTypes.bool,
	timing: PropTypes.string,
	offset: PropTypes.number,
}
