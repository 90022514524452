import ImgDesc from 'Templates/ImgDesc';
import ptLoading from 'Assets/images/pt-loading.gif';
import ptSettings from 'Assets/images/pt-settings.gif';
import ptHome from 'Assets/images/pt-home.gif';
import ptLogo from 'Assets/images/pt-logo.jpg';
import * as React from 'react';
export default {
  ImgDesc,
  ptLoading,
  ptSettings,
  ptHome,
  ptLogo,
  React
};