import React from 'react'
import gsap from 'gsap'
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'
import { Link } from 'gatsby'
import {isMobile} from 'react-device-detect'
import PropTypes from "prop-types"

//    _______ _                _ _              __  __      _   _               _
//   |__   __(_)              | (_)            |  \/  |    | | | |             | |
//      | |   _ _ __ ___   ___| |_ _ __   ___  | \  / | ___| |_| |__   ___   __| |___
//      | |  | | '_ ` _ \ / _ \ | | '_ \ / _ \ | |\/| |/ _ \ __| '_ \ / _ \ / _` / __|
//      | |  | | | | | | |  __/ | | | | |  __/ | |  | |  __/ |_| | | | (_) | (_| \__ \
//      |_|  |_|_| |_| |_|\___|_|_|_| |_|\___| |_|  |_|\___|\__|_| |_|\___/ \__,_|___/
//
//
/**
 * Fits any amount of durations into the total duration, maintaining ratio
 * 
 * @param {Number} duration The total duration to fit
 * @param  {...Number} durs The durations to map
 */
export function getDurations(duration, ...durs) {
	let total = durs.reduce((a, b) => a+b)
	return durs.map(dur => dur * duration / total)
}

function fade(timeline, node, length = 1, fadesIn = true) {	
	timeline.fromTo(
		node,
		{ opacity: fadesIn ? 0 : 1 },
		{ duration: length, opacity: fadesIn ? 1 : 0 }
	)

	return timeline
}
function fadeIn(timeline, node, length = 1) {
	return fade(timeline, node, length, true)
}
function fadeOut(timeline, node, length = 1) {
	return fade(timeline, node, length, false)
}

//    ____            _        _______                  _ _   _
//   |  _ \          (_)      |__   __|                (_) | (_)
//   | |_) | __ _ ___ _  ___     | |_ __ __ _ _ __  ___ _| |_ _  ___  _ __  ___
//   |  _ < / _` / __| |/ __|    | | '__/ _` | '_ \/ __| | __| |/ _ \| '_ \/ __|
//   | |_) | (_| \__ \ | (__     | | | | (_| | | | \__ \ | |_| | (_) | | | \__ \
//   |____/ \__,_|___/_|\___|    |_|_|  \__,_|_| |_|___/_|\__|_|\___/|_| |_|___/
//
//

const Basics = {
	fadeIn: ({enter, node, exit, from, duration}) => {
		let timeline = gsap.timeline()

		fadeIn(timeline, node)
	},
	fadeOut: ({exit, node}) => {
		let timeline = gsap.timeline()

		fadeOut(timeline, node)
	},
}

//     _____                 _       _   _______                  _ _   _
//    / ____|               (_)     | | |__   __|                (_) | (_)
//   | (___  _ __   ___  ___ _  __ _| |    | |_ __ __ _ _ __  ___ _| |_ _  ___  _ __  ___
//    \___ \| '_ \ / _ \/ __| |/ _` | |    | | '__/ _` | '_ \/ __| | __| |/ _ \| '_ \/ __|
//    ____) | |_) |  __/ (__| | (_| | |    | | | | (_| | | | \__ \ | |_| | (_) | | | \__ \
//   |_____/| .__/ \___|\___|_|\__,_|_|    |_|_|  \__,_|_| |_|___/_|\__|_|\___/|_| |_|___/
//          | |
//          |_|

const enterNav = (node, timeline, from='/') => {

	if(from !== '/'){return timeline}

	let nav = node.querySelector('.site-navigation')
	let pad = nav.querySelector('.nav-pad')
	
	timeline.fromTo(nav, 1, {y: '-100%'}, {y: '0%', ease: 'expo.out'})
	timeline.fromTo('.navbar-item', 1, {y: '-200%'}, {y: '0%', stagger: 0.25, ease: 'back.out(1)'}, '-=1')
	timeline.fromTo(pad, 1, {x: '-200%', y: '0%', rotation: '180_cw'}, {x: '100%', y: '0%', rotation: '0_cw', ease: 'back.out(2)'}, '-=1.5')
	return timeline
}

const exitNav = (node, timeline, to='/') => {
	if(to !== '/') {return timeline}
	let nav = node.querySelector('.site-navigation')

	timeline.fromTo(nav, 1, {transform: 'translateY(0%)'}, {transform: 'translateY(-100%)', ease: 'back.in(1.4)'})
	return timeline
}

const enterHome = ({enter, node, exit, from, duration}) => {
	let timeline = gsap.timeline()
	let titles = node.querySelector('.titles')

	timeline.addLabel('start')
	.to(
		titles,
		{
			opacity: 1,
			duration: duration,
			ease: 'power2.in'
		},
		'start'
	)
	.to(
		'.home-bg',
		{
			opacity: null,
			duration: duration,
			ease: 'power2.in'
		},
		'start'
	)
	.fromTo(
		'.gamepad-home',
		{
			y: window.outerHeight
		},
		{
			ease: 'back.out(1)',
			y: 0,
			duration: duration/1.5,
		},
		`start`
	)
	.to('.mobile-pad',
		{
			opacity: 1,
			duration: duration,
			stagger: duration / 4,
			ease: 'ease.out'
		},
		`start`
	)
	timeline.play()
}

const exitHome = ({exit, node, enter, to, duration}) => {
	let timeline = gsap.timeline()
	let titles = node.querySelector('.titles')

	timeline.addLabel('start')
	.to(
		titles,
		{
			opacity: 0,
			duration: duration,
			ease: 'power2.in'
		},
		'start'
	)
	.to(
		'.home-bg',
		{
			opacity: 0,
			duration: duration,
			ease: 'power2.in'
		},
		'start'
	)
	.to(
		'.gamepad-home',
		duration,
		{
			stagger: duration / 2,
			ease: 'back.in(1.5)',
			y: window.outerHeight,
		},
		`start`
	)
	.to('.mobile-pad',
		{
			opacity: 0,
			duration: duration,
			stagger: duration / 4,
			ease: 'ease.out'
		},
		`start`
	)
	timeline.play()
}

export const enterAbout = ({enter, node, exit, from, duration}) => {	
	let timeline = gsap.timeline()
	let phone = node.querySelector('.about-phone-container');
	let mainCount = node.querySelectorAll('.about-main-content').length

	let [mainDur, phoneDur] = getDurations(duration, 1.5, 2)
	
	timeline.addLabel('enterIn')
	enterNav(node, timeline, from)
	.fromTo('.about-main-content', mainDur, {opacity: 0, x: '100%'}, {opacity:1, x: '0%', stagger: mainDur/mainCount, ease: 'expo.out'}, 'enterIn+=0.25')
	.fromTo(phone, phoneDur, {x: '-150%'}, {x: '0%', ease: 'expo.out'}, 'enterIn+=0.25')

	return timeline.restart()
}

export const exitAbout = ({exit, node, enter, to, duration}) => {
	let timeline = gsap.timeline()
	let phone = node.querySelector('.about-phone-container');
	let mainCount = node.querySelectorAll('.about-main-content').length
	

	let [mainDur, phoneDur] = getDurations(duration, 1, 1.5)

	exitNav(node, timeline, to)
	.addLabel('enterOut')
	.fromTo('.about-main-content', mainDur, {opacity:1, x: '0%'}, {opacity: 0, x: '100%', stagger: mainDur/mainCount, ease: 'power1.in'}, 'enterOut')
	.fromTo(phone, phoneDur, {x: '0%'}, {x: '-150%', ease: 'power1.in'}, 'enterOut')

	timeline.restart();
}

export const enterExperience = ({enter, node, exit, from, duration}) => {
	let timeline = gsap.timeline()
	let computer = node.querySelector('.xp-computer')

	let [compDur, keysDur] = getDurations(duration, 1.5, 1)

	timeline.addLabel('start')
	enterNav(node, timeline, from)
	.fromTo(computer, compDur, {y: '-200%', opacity: 0}, {y: '0%', opacity: 1, ease: 'circ.out'}, 'start')
	.fromTo('.keyboard-key', keysDur, {y: '-100%', opacity: 0}, {y: '0%', stagger: 0.01, opacity: 1, ease: 'circ.out'}, 'start+=0.5')

	return timeline.restart()
}

export const exitExperience = ({enter, node, exit, to, duration}) => {
	let timeline = gsap.timeline()
	let totalKeys = node.querySelectorAll('.keyboard-key').length
	let totalProjects = node.querySelectorAll('.xp-project').length

	let [compDur, keysDur] = getDurations(duration, 1, 0.5)

	timeline.addLabel('start')
	exitNav(node, timeline, to)
	.fromTo('.xp-computer', compDur, {y: '0%', opacity: 1}, {y: '-110%', opacity: 0, ease: 'back.in(1)'}, 'start')
	.fromTo('.keyboard-key', keysDur, {y: '0%', opacity: 1}, {y: '100%', stagger: -keysDur/totalKeys, opacity: 0, ease: 'power2.out'}, 'start')
	.fromTo(
		'.xp-project',
		{ opacity: 1 },
		{
			opacity: 0,
			duration: duration,
			stagger: Math.min(0.15, duration/totalProjects),
			ease: 'expo.out',
		},
		'start'
	)
	return timeline.restart()
}

export const enterContact = ({enter, node, exit, from, duration}) => {
	let timeline = gsap.timeline()
	let micCount = node.querySelectorAll('.mic').length

	timeline.addLabel('start')
	enterNav(node, timeline, from)
	.from('.mic', {y: '100%', duration: duration, stagger: duration/micCount, ease: 'elastic.out(1, 1)'}, 'start')

	return timeline
}

export const exitContact = ({enter, node, exit, to, duration}) => {
	let timeline = gsap.timeline()
	let micCount = node.querySelectorAll('.mic').length + 3
	let offset = duration/micCount
	
	timeline.addLabel('start')
	exitNav(node, timeline, to)
	timeline.to('.mic', {y: '100%', duration: duration - 3 * offset, stagger: offset, ease: 'elastic.in(1, 1)'}, 'start')

	return timeline
}

export const enterBlog = ({enter, node, exit, from, duration}) => {
	let timeline = gsap.timeline()

	let menuDelay = 0.1
	let menuDur = 0.5

	timeline.addLabel('start')
	enterNav(node, timeline, from)
	if(isMobile) {
		timeline.to('.blog-menu', {x: '150%', duration: 0}, 'start');
	}
	timeline.fromTo(
		'.blog-tv-container', 
		{
			y: '100%',
		},
		{
			y: '0%',
			duration: duration,
			ease: 'elastic.out(0.25)'
		},
		'start'
	)
	.fromTo(
		'.blog-page-title',
		{
			opacity: '0'
		},
		{
			opacity: '1',
			delay: 0.5,
			duration: duration * 3,
		},
		'start'
	)
	if(isMobile) {
		timeline.add(() => {
			let tv = node.querySelector('.blog-tv-container');
			tv.style.transform = '';
		}, duration)
		.to(
			'.blog-menu',
			{
				x: '100%',
				duration: menuDur,
				ease: 'power3.out'
			},
			duration+=menuDelay
		).add(() => {
			let tv = node.querySelector('.blog-menu');
			tv.style.transform = '';
		}, duration+=(menuDelay+menuDur))
	}

	return timeline
}

export const exitBlog = ({enter, node, exit, to, duration}) => {
	let timeline = gsap.timeline()
	
	timeline.addLabel('start')
	exitNav(node, timeline, to)
	.fromTo(
		'.blog-tv-container', 
		{
			y: '0%',
		},
		{
			y: '100%',
			duration: duration,
			ease: 'elastic.in(0.25)'
		},
		'start'
	)
	.fromTo(
		'.blog-page-title',
		{
			opacity: '1'
		},
		{
			opacity: '0',
			duration: duration * 0.85,
		},
		'start'
	)

	return timeline
}

//    _______                  _ _   _                   __  __
//   |__   __|                (_) | (_)                 |  \/  |
//      | |_ __ __ _ _ __  ___ _| |_ _  ___  _ __  ___  | \  / | __ _ _ __
//      | | '__/ _` | '_ \/ __| | __| |/ _ \| '_ \/ __| | |\/| |/ _` | '_ \
//      | | | | (_| | | | \__ \ | |_| | (_) | | | \__ \ | |  | | (_| | |_) |
//      |_|_|  \__,_|_| |_|___/_|\__|_|\___/|_| |_|___/ |_|  |_|\__,_| .__/
//                                                                   | |
//                                                                   |_|

const trans = {
	'/': {
		enter: {
			transition: enterHome,
			duration: 2,
			delay: 0,
		},
		exit: {
			transition: exitHome,
			duration: 1,
			delay: 0,
		},
	},
	'/about': {
		enter: {
			transition: enterAbout,
			duration: 3,
			delay: 0,
		},
		exit: {
			transition: exitAbout,
			duration: 3,
			delay: 0,
		},
	},
	'/experience': {
		enter: {
			transition: enterExperience,
			duration: 2,
			delay: 0,
		},
		exit: {
			transition: exitExperience,
			duration: 1.5,
			delay: 0,
		},
	},
	'/contact': {
		enter: {
			transition: enterContact,
			duration: 0.75,
			delay: 0,
		},
		exit: {
			transition: exitContact,
			duration: 1.25,
			delay: 0,
		},
	},
	'/blog': {
		enter: {
			transition: enterBlog,
			duration: 1.5,
			delay: 0,
		},
		exit: {
			transition: exitBlog,
			duration: 1,
			delay: 0,
		},
	},
}

//    _______                  _ _   _               _      _       _
//   |__   __|                (_) | (_)             | |    (_)     | |
//      | |_ __ __ _ _ __  ___ _| |_ _  ___  _ __   | |     _ _ __ | | _____
//      | | '__/ _` | '_ \/ __| | __| |/ _ \| '_ \  | |    | | '_ \| |/ / __|
//      | | | | (_| | | | \__ \ | |_| | (_) | | | | | |____| | | | |   <\__ \
//      |_|_|  \__,_|_| |_|___/_|\__|_|\___/|_| |_| |______|_|_| |_|_|\_\___/

export const TransLink = ({
	children,
	to,
	from,
	onEnter = () => {},
	onExit = () => {},
	delays = {},
	durs = {},
	...etc
}) => {
	if (!delays.hasOwnProperty('enter')) {
		delays.enter = 0
	}
	if (!delays.hasOwnProperty('exit')) {
		delays.exit = 0
	}
	if (!durs.hasOwnProperty('enter')) {
		durs.enter = 1
	}
	if (!durs.hasOwnProperty('exit')) {
		durs.exit = 1
	}

	return (
		<TransitionLink
			to={to}
			exit={{
				length: durs.exit,
				delay: delays.exit,
				trigger: ({ exit, node, entry }) => 
					onExit({exit: exit, node: node, entry: entry, from: from, to: to, duration: durs.exit}),
			}}
			entry={{
				length: durs.enter,
				delay: delays.enter,
				trigger: ({ entry, node, exit }) =>
					onEnter({exit: exit, node: node, entry: entry, from: from, to: to, duration: durs.enter}),
			}}
			{...etc}
		>
			{children}
		</TransitionLink>
	)
}

function getRoot(path) {
	let pathStr = `${path}`
	let slashIndex = pathStr.indexOf('/', 1);
	if(slashIndex !== -1) {
		pathStr = pathStr.slice(0, slashIndex)
	}
	return pathStr
}

export const AutoTransLink = ({ children, to, from, wait, excludeSelf, excludeChildren=false, ...etc }) => {

	let toKey = to;
	let fromKey = from;
	if(!trans[toKey]) {
		toKey = getRoot(to);
	}
	if(!trans[fromKey]) {
		fromKey = getRoot(from);
	}

	let toHasEnter = trans[toKey] && trans[toKey].enter
	let fromHasExit = trans[fromKey] && trans[fromKey].exit

	let waitFor = 0
	if(wait && fromHasExit) {
		let dur = trans[fromKey].exit.duration ? trans[fromKey].exit.duration : 0
		let delay = trans[fromKey].exit.delay ? trans[fromKey].exit.delay : 0

		waitFor = dur + delay
	}

	if(to === from && excludeSelf) {
		return (
			<Link to={to} {...etc}>{children}</Link>
		)
	}
	
	if(toKey === fromKey && excludeChildren) {
		return (
			<Link to={to} {...etc}>{children}</Link>
		)
	}

	return (
		<TransLink
			to={to}
			from={from}
			onEnter={
				toHasEnter
					? trans[toKey].enter.transition
					: () => {}
			}
			onExit={
				fromHasExit
					? trans[fromKey].exit.transition
					: () => {}
			}
			durs={{
				enter: toHasEnter
					? trans[toKey].enter.duration
					: null,
				exit: fromHasExit
					? trans[fromKey].exit.duration
					: null,
			}}
			delays={{
				enter: toHasEnter
					? trans[toKey].enter.delay + waitFor
					: null,
				exit: fromHasExit
					? trans[fromKey].exit.delay
					: null,
			}}
			{...etc}
		>
			{children}
		</TransLink>
	)
}

AutoTransLink.propTypes = {
	to: PropTypes.string, 
	from:PropTypes.string, 
	wait: PropTypes.bool, 
	excludeSelf: PropTypes.bool
}
AutoTransLink.defaultProps = {
	to: "/",
	wait: false,
	excludeSelf: true
}

function getTransLinkFor(props, from) {
	let { to, children, ...etc } = props
	return (
		<AutoTransLink to={to} from={from} {...etc}>
			{children}
		</AutoTransLink>
	)
}

export const AboutLink = props => {
	return getTransLinkFor(props, '/about')
}

export const HomeLink = props => {
	return getTransLinkFor(props, '/')
}
