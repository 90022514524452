import ImgDesc from 'Templates/ImgDesc';
import imgHome from "../../../../src/projects/Fuse/images/Fuse__Home.png";
import imgModel from "../../../../src/projects/Fuse/images/Fuse__MVVM-Code.jpg";
import imgFirestore from "../../../../src/projects/Fuse/images/Fuse__Firestore-Database.jpg";
import * as React from 'react';
export default {
  ImgDesc,
  imgHome,
  imgModel,
  imgFirestore,
  React
};