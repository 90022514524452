import React, { HTMLAttributes, useEffect, useState } from 'react'
import CodeTheme from './code-theme/code-theme';
import EngineTheme from './engine-theme/engine-theme';
import { IProjectTab, IProjectData } from './project-templates.interfaces';


interface IProjectTemplate extends HTMLAttributes<HTMLElement> {
    theme: string;
    project: IProjectData;
    subtheme?: string;
    themeData: any;
    tabs?: IProjectTab[];
    images?: string[];
}
export default function ProjectTemplate({ theme='code', project, tabs, subtheme }: IProjectTemplate) {

    const [hash, setHash] = useState('');
    useEffect(() => {
		if(typeof window !== 'undefined') {
			let location = window.location;
			let newHash = location.hash.replace('#', '');
			if(newHash && newHash !== hash) {
				setHash(newHash);
			}
		}
	}, [hash])

    switch (theme) {
        case 'code':
            return (
                <CodeTheme project={project} tabs={tabs} language={subtheme} hash={hash} />
            )
        case 'engine':
            return (
                <EngineTheme project={project} tabs={tabs} hash={hash} />
            )
        default:
            break;
    }
}
