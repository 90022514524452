import Move from 'Assets/icons/Move.svg'
import Scale from 'Assets/icons/Scale.svg'
import Rotate from 'Assets/icons/Rotate.svg'
import Zoom from 'Assets/icons/Zoom.svg'
import Pause from 'Assets/icons/Pause.svg'
import Play from 'Assets/icons/Play.svg'
import Skip from 'Assets/icons/Skip.svg'
import Info from 'Assets/icons/Info.svg'
import MyObject from 'Assets/icons/Object.svg'
import Scene from 'Assets/icons/Scene.svg'
import Text from 'Assets/icons/Text.svg'
import Cursor from 'Assets/icons/Cursor.svg'
import Transform from 'Assets/icons/Transform.svg'
import Tag from 'Assets/icons/Tag.svg'
import ThreeD from 'Assets/icons/ThreeD.svg'
import ThreeDFilled from 'Assets/icons/ThreeDFilled.svg'
import { FullscreenIconStatic, FullscreenIconReversed } from 'Assets/svgs/fullscreen-icon'
import { HTMLAttributes } from 'react'

const Icons: {[key: string]: HTMLAttributes<SVGElement>} = {
	Move: Move,
	Scale: Scale,
	Rotate: Rotate,
	Zoom: Zoom,
	Pause: Pause,
	Play: Play,
	Skip: Skip,
	Info: Info,
	Object: MyObject,
	Scene: Scene,
	Text: Text,
	Cursor: Cursor,
	Transform: Transform,
	Tag: Tag,
	ThreeD: ThreeD,
	ThreeDFilled: ThreeDFilled,
	Fullscreen: FullscreenIconStatic,
	FullscreenReversed: FullscreenIconReversed,
}

// enum IconsEnum {
// 	Move,
// 	Scale,
// 	Rotate,
// 	Zoom,
// 	Pause,
// 	Play,
// 	Skip,
// 	Info,
// 	Object,
// 	Scene,
// 	Text,
// 	Cursor,
// 	Transform,
// 	Tag,
// 	ThreeD,
// 	ThreeDFilled,
// 	Fullscreen,
// }
// interface IIcons {
// 	icon: IconsEnum;
// }

// function Icons({ icon }: IIcons) {
// 	let Icon = icons[icon];
// 	return (
// 		<Icon />
// 	)
// }

export default Icons
