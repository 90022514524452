import projImg2 from "../../../../src/projects/Modeling/project.png";
import modelBooth from "../../../../src/projects/Modeling/images/booth.png";
import modelDesk from "../../../../src/projects/Modeling/images/desk.png";
import modelFence from "../../../../src/projects/Modeling/images/fence.png";
import modelBoothThumb from "../../../../src/projects/Modeling/images/booth-thumb.png";
import modelDeskThumb from "../../../../src/projects/Modeling/images/desk-thumb.png";
import modelFenceThumb from "../../../../src/projects/Modeling/images/fence-thumb.png";
import * as React from 'react';
export default {
  projImg2,
  modelBooth,
  modelDesk,
  modelFence,
  modelBoothThumb,
  modelDeskThumb,
  modelFenceThumb,
  React
};