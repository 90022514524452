/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import './layout.scss'
import Navbar from './navbar'

const Layout = ({ children, includeNav, includeFooter }) => {
	// const data = useStaticQuery(graphql`
	// 	query SiteTitleQuery {
	// 		site {
	// 			siteMetadata {
	// 				title
	// 			}
	// 		}
	// 	}
	// `)

	if (typeof window !== "undefined") {
		// eslint-disable-next-line global-require
		require("smooth-scroll")('a[href*="#"]')
	}

	return (
		<>
			{/* <Header siteTitle={data.site.siteMetadata.title} /> */}
			{includeNav && <Navbar />}
			<div
				style={{
					// margin: `0 auto`,
					// maxWidth: 960,
					// padding: `0 1.0875rem 1.45rem`,
					height: '100%'
				}}
			>
				<main style={{
					height: '100%'
				}}>{children}</main>
				{includeFooter && (
					<footer>
						© {new Date().getFullYear()},
						Built with
						{` `}
						<a href="https://www.gatsbyjs.org">
							Gatsby
						</a>
					</footer>
				)}
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}
Layout.defaultProps = {
	includeNav: false,
	includeFooter: false,
}

export default Layout
