import React, { HTMLAttributes, StyleHTMLAttributes } from 'react'
import styles from './ImgDesc.module.scss'

interface IImgDesc extends HTMLAttributes<HTMLDivElement> {
    src: string | string[];
    text: string | JSX.Element | JSX.Element[];
    reverse?: boolean;
    column?: boolean;
    centerText?: boolean;
    imgStyle: StyleHTMLAttributes<HTMLImageElement>;
    txtStyle: StyleHTMLAttributes<HTMLDivElement>;
}

export default function ImgDesc({ src, text, reverse=false, centerText=false, column, imgStyle, txtStyle }: IImgDesc) {

    if(typeof src === 'string') {
        src = [src]
    }

	return (
		<div className={`${styles.main} ${reverse ? styles.reverse : ''} ${column ? styles.column : ''}`}>
			<div className={styles.images}>
                {
                    (src as string[]).map((s, index) => {
                        return (
                            <img key={index} src={s} style={{width: '100%', ...imgStyle}} />
                        )
                    })
                }
            </div>
			<div className={styles.text} style={centerText ? {textAlign: 'center'} : {}}><div>{text}</div></div>
		</div>
	)
}
