import projImg from "../../../../src/projects/Demos/project.png";
import projImgThumb from "../../../../src/projects/Demos/project-thumb.png";
import vidWorldGen1 from "../../../../src/projects/Demos/images/WorldGen-01.mp4";
import vidWorldGen2 from "../../../../src/projects/Demos/images/WorldGen-02.mp4";
import sunVid from "../../../../src/projects/Demos/images/Sun.mp4";
import camVid from "../../../../src/projects/Demos/images/Camera.mp4";
import * as React from 'react';
export default {
  projImg,
  projImgThumb,
  vidWorldGen1,
  vidWorldGen2,
  sunVid,
  camVid,
  React
};