import React, { useEffect, HTMLAttributes, useState } from 'react'
import styles from './code-theme.module.scss'
import String2JSX from 'string2jsx'
import { IProjectData, IProjectTheme } from '../project-templates.interfaces'
import { urlName, camelCase, snakeCase } from '../../../utils/tools/naming'


interface ICodeTheme extends IProjectTheme {
	language?: string
}
interface ILanguageTemplate {
	[key: string]: (data: IProjectData) => string[]
}

const languageTemplates: ILanguageTemplate = {
	json: (data: IProjectData) => [
		`{\n`,
		`\tother={"title"}: string={"${data.title}"},\n`,
		`\tother={"type"}: string={"${data.type}"},\n`,
		`\tother={"role"}: string={"${data.role}"},\n`,
		`\tother={"dates"}: {\n`,
		`\t\tother={"from"}: date={"${data.dates.from}}",\n`,
		`\t\tother={"to"}: date={"${data.dates.to}"}\n`,
		`\t},\n`,
		`\tother={"description"}: string={"${data.desc}"},\n`,
		`}`,
		``,
		``,
		``,
	],
	java: (data: IProjectData) => [
		`this={public class} title={${data.title}} this={extends} type={${data.type}} {\n`,
		`\tthis={public} Roles myRole = Roles.role={${camelCase(data.role)}};\n`,
		`\tthis={public} String dates = date={"${data.dates.from} - ${data.dates.to}"};\n`,
		`\n`,
		`\tthis={public} ${data.title}() {\n`,
		`\t\tthis={super}();\n`,
		`\t\tthis={this}.method={setDescription}(\n`,
		`desc={\t\t\t"${data.desc}"\n}`,
		`\t\t);\n`,
		`\t}\n`,
		`\n`,
		`\tthis={private void} method={setDescription}(String text) {\n`,
		`\t\tthis={if}(text != method={null}) {\n`,
		``,
	],
	swift: (data: IProjectData) => [
		`this={public class} title={${data.title}} this={extends} type={${data.type}} {\n`,
		`\tthis={let} myRole: Roles = .role={${camelCase(data.role)}}\n`,
		`\tthis={let} dates: String = date={"${data.dates.from} - ${data.dates.to}"}\n`,
		`\n`,
		`\tthis={init}() {\n`,
		`\t\tthis={super}.this={init}()\n`,
		`\t\tthis={self}.method={setDescription}(\n`,
		`desc={\t\t\t"${data.desc}"\n}`,
		`\t\t)\n`,
		`\t}\n`,
		`\n`,
		`\tthis={func} method={setDescription}(_ text: String?) {\n`,
		`\t\tthis={if let} txt = text {\n`,
		``,
	],
	tsx: (data: IProjectData) => [
		`export const title={${data.title}} = ( props: type={${data.type}} ) => {\n`,
		`\tconst [ myRole ] = method={useState}(Roles.role={${data.role}})\n`,
		`\tconst [ dates ] = method={useState}(date={"${data.dates.from} - ${data.dates.to}"})\n`,
		`\treturn (\n`,
		`\t\t<other={Description}>\n`,
		`\t\t\tdesc={${data.desc}}\n`,
		`\t\t</other={Description}>\n`,
		`\t)\n`,
		`}`,
	],
	jsx: (data: IProjectData) => [
		`export const title={${data.title}} = props => (\n`,
		`\t<other={div} method={className}={props.type={${data.type}}}>\n`,
		`\t\t<other={MyRole}>role={${data.role}})</other={MyRole}>\n`,
		`\t\t<other={Dates} method={from}={date={"${data.dates.from}}} method={to}={date={"${data.dates.to}}} />`,
		`\t\t<other={Description}>\n`,
		`\t\t\tdesc={${data.desc}}\n`,
		`\t\t</other={Description}>\n`,
		`\t</other={div}>\n`,
		`)`,
	],
	py: (data: IProjectData) => [
		`class title={${data.title}} ( type={${data.type}} ):\n`,
		`\tdef method={__init__}(self):\n`,
		`\t\tsuper().__init__()\n`,
		`\t\tself.my_role = role={${snakeCase(data.role)}}()\n`,
		`\t\tself.dates = {other={"from"}: date={"${data.dates.from}"}, other={"to"}: date={"${data.dates.to}"}}\n`,
		`\n`,
		`\t\tself.method={set_description}(desc={"${data.desc}"})\n`,
		`\n`,
		`\tdef method={set_description}(self, text):\n`,
		`\t\tif text is not None:\n`,
		``,
	],
}

export default function CodeTheme({
	language = 'swift',
	tabs = [],
	project,
	hash=''
}: ICodeTheme) {
	// === Create Template === //
	const template = languageTemplates[language]
		? languageTemplates[language](project)
		: []

    // === Initial Content === //
    const MainBody = () => {
        return (
            <table>
                <tbody>
                    {template.map((text, index) => (
                        <tr key={index}>
                            <td className={styles.line}>{index + 1}&nbsp;</td>
                            <td>
                                <String2JSX
                                    children={text}
                                    map={conversionMap}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
    
    // === Create Tabs === //
    let mainTab = {
        name: project.title,
        ext: language,
        html: <MainBody />
    };
    if(tabs.length === 0 || (tabs[0].name !== mainTab.name && tabs[0].ext !== mainTab.ext)) {
        tabs.unshift(mainTab)
    }

    // === Tab Toggle === //
    const [activeTab, setActiveTab] = useState(tabs[0]);
	function toggleActiveTab(
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null,
		newTab: number | null
	) {
        // === Remove Current Highlights === //
		let tabElements = document.querySelectorAll(`.${styles.file}`)
		tabElements.forEach(elt => {
			elt.classList.remove(styles.activeTab)
        });
        let clickedTab;

		// === Add Highlights === //
		if(newTab) {
			clickedTab = document.querySelector(`[data-tab="${newTab}"]`)
			clickedTab?.classList.add(styles.activeTab, `[data-tab="${newTab}"]`)
			
			setActiveTab(tabs[newTab]);
		} else {
			clickedTab = e?.target as HTMLElement;
			clickedTab?.classList.add(styles.activeTab)

			let tabIndex: number = parseInt(clickedTab?.getAttribute('data-tab')??"0");
			setActiveTab(tabs[tabIndex]);
		}
	}
	
	useEffect(() => {
		if(hash && hash !== activeTab.name) {
			let tabIndex = tabs.findIndex(tab => urlName(tab.name) === hash);
			if(tabIndex !== -1) {
				toggleActiveTab(null, tabIndex);
			}
		}
	}, [hash])

	return (
		<ThemeMain className={styles.root}>
			<nav className={styles.navbar}>
				<ul className={styles.navList}>
                    {tabs.map((item, index)=> {
                        return (
                            <li key={index}>
                                <a 
                                    className={`${styles.file} ${index === 0 && styles.activeTab}`}
                                    data-tab={index}
                                    onClick={e => toggleActiveTab(e, null)}
                                    href={`#${urlName(item.name)}`}
                                >
                                    {item.name}{item.ext && `.${item.ext}`}
                                </a>
                            </li>
                        )
                    })}
				</ul>
			</nav>
			<div className={styles.editor}>
				{activeTab.html}
			</div>
		</ThemeMain>
	)
}

interface IThemeMain extends HTMLAttributes<HTMLElement> {
	bg?: string
}
const ThemeMain = ({ children, bg, ...etc }: IThemeMain) => (
	<div
		style={{
			background: bg,
			width: '100%',
			height: '100%',
		}}
		{...etc}
	>
		{children}
	</div>
)
interface ISpanTab extends HTMLAttributes<HTMLSpanElement> {
	tabSize?: number
	tabCount?: number
}
const Tab = ({ tabSize = 3, tabCount = 1 }: ISpanTab) => {
	let tabs = []
	for (let i = 0; i < tabCount; i++) {
		let spaces = []
		for (let j = 0; j < tabSize; j++) {
			spaces.push(<Space key={j} />)
		}
		tabs.push(
			<span key={i} className={styles.tab}>
				{spaces}
			</span>
		)
	}
	return <>{tabs}</>
}
const Space = (props: HTMLAttributes<HTMLSpanElement>) => {
	return <span className={styles.space}>&nbsp;</span>
}
const NewLine = (props: HTMLAttributes<HTMLSpanElement>) => {
	return <span className={styles.newline}>&nbsp;</span>
}
// const Line = ({ line, children, ...etc }: ILine) => {
//     return <tr {...etc}><td className={styles.line}>{line}&nbsp;</td><td>{children}</td></tr>
// }
const conversionMap = [
    // === Title === //
	{
		from: /title={(.*?)}/,
		to: <span className={styles.title} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Type === //
	{
		from: /type={(.*?)}/,
		to: <span className={styles.subtitle} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Role === //
	{
		from: /role={(.*?)}/,
		to: <span className={styles.role} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Date === //
	{
		from: /date={(.*?)}/,
		to: <span className={styles.string} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Desc === //
	{
		from: /desc={([^]*?)}/,
		to: (
			<String2JSX
				parent={<span className={styles.string} />}
				map={[
					{
						from: /\t/,
						to: <Tab />,
					},
					{
						from: / /,
						to: <Space />,
					},
					{
						from: /\n/,
						to: <NewLine />,
					}
				]}
			/>
		),
		matchGroup: 1,
		isChild: true,
		props: {
			className: styles.string,
		},
    },
    // === This === //
	{
		from: /this={(.*?)}/,
		to: (
			<String2JSX
				parent={<span className={styles.self} />}
				map={[
					{
						from: /\t/,
						to: <Tab />,
					},
					{
						from: / /,
						to: <Space />,
					},
					{
						from: /\n/,
						to: <NewLine />,
					}
				]}
			/>
		),
		matchGroup: 1,
		isChild: true,
    },
    // === Other === //
	{
		from: /other={(.*?)}/,
		to: (
			<String2JSX
				parent={<span className={styles.other} />}
				map={[
					{
						from: /\t/,
						to: <Tab />,
					},
					{
						from: / /,
						to: <Space />,
					},
					{
						from: /\n/,
						to: <NewLine />,
					}
				]}
			/>
		),
		matchGroup: 1,
		isChild: true,
    },
    // === String === //
	{
		from: /string={(.*?)}/,
		to: (
			<String2JSX
				parent={<span className={styles.string} />}
				map={[
					{
						from: /\t/,
						to: <Tab />,
					},
					{
						from: / /,
						to: <Space />,
					},
					{
						from: /\n/,
						to: <NewLine />,
					}
				]}
			/>
		),
		matchGroup: 1,
		isChild: true,
    },
    // === Method === //
	{
		from: /method={(.*?)}/,
		to: <span className={styles.method} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Defaults === //
	{
		from: /\b(class|export|public|const|let|var|this(?!=)|init|def|if|is|not|return)\b/,
		to: <span className={styles.self} />,
		matchGroup: 1,
		isChild: true,
    },
    // === Tab === //
	{
		from: /\t/,
		to: <Tab />,
    },
    // === Newline === //
	{
		from: /\n/,
		to: <NewLine />,
    },
    // === Space === //
	{
		from: / /,
		to: <Space />,
	},
]
